import React, {Fragment} from 'react'
import {IMAGE_TYPES, MAX_IMAGE_UPLOAD_SIZE, MAX_SVG_UPLOAD_SIZE, SVG_TYPE} from '../../../../constant'
import useUploadFile from '../../../../hooks/useUploadFile'
import {toaster} from '../../../../shared/PFToast'
import {useSelectedVariantContext} from '../../context/SelectedVariantContext'
import {actionSetRunningImageCompress} from '../../context/SelectedVariantContext/action'
import {ReactComponent as ComputerIcon} from '../../../../assets/icons/computer.svg'

const LIMIT_CAPACITY = 9000000000 // 9gb

const UploadByComputer = (props) => {
    const {toggle, accept, capacity = null} = props
    const {dispatch} = useSelectedVariantContext()
    const {uploadFile} = useUploadFile()

    const _validateFileUpload = (file) => {
        let isValid = true
        if (!IMAGE_TYPES.includes(file.type) && file.type !== SVG_TYPE) {
            isValid = false
            toaster({
                type: 'error',
                message: 'Your uploaded file is not supported',
            })
        }
        if (file.size > MAX_IMAGE_UPLOAD_SIZE && IMAGE_TYPES.includes(file.type)) {
            isValid = false
            toaster({
                type: 'error',
                message: 'Your image exceeded allowed limit for file (JPG, PNG)',
            })
        }
        if (file.size > MAX_SVG_UPLOAD_SIZE && file.type === SVG_TYPE) {
            isValid = false
            toaster({
                type: 'error',
                message: 'Your image exceeded allowed limit for file SVG',
            })
        }
        return isValid
    }

    const _handleChangeFile = (e) => {
        const {files} = e.target

        _onChangeFile(files)
    }

    const _onChangeFile = async (files) => {
        const file = files[0]
        if (!_validateFileUpload(file)) return
        // check if used capacity greater than limit capacity
        if (capacity && capacity.used > capacity.capacity) {
            return toaster({
                type: 'error',
                message:
                    'The storage limit of design library is reached. Please delete some of your unneeded files to free up spaces',
                width: 500,
            })
        }
        if (capacity && capacity.used > LIMIT_CAPACITY) {
            if (capacity.used + file.size > capacity.capacity) {
                return toaster({
                    type: 'error',
                    message:
                        'The storage limit of design library is reached. Please delete some of your unneeded files to free up spaces',
                    width: 500,
                })
            }
            toaster({
                type: 'warning',
                message:
                    'The storage of design library is almost reached the allowed limit. Please delete some of your unneeded files to free up spaces',
                duration: 10000,
                width: 520,
            })
        }
        dispatch(actionSetRunningImageCompress(true))
        toggle()
        await uploadFile(file)
    }

    return (
        <Fragment>
            <div className="UploadFromLocal">
                <label>Upload from:</label>
                <div className="YourDevice">
                    <input
                        type='file'
                        accept={accept || 'image/png, image/jpeg, image/jpg'}
                        multiple={false}
                        onChange={_handleChangeFile}
                        style={{width: 100}}
                        id="inputUploadArtwork_computer"
                        className="d-none"
                        disabled={false}
                    />
                    <label htmlFor="inputUploadArtwork_computer" id="inputUploadArtwork_computer"
                           className="mb-0 cursor-pointer align-items-center">
                        <ComputerIcon className="mr-2"/>
                        Your device
                    </label>
                </div>
            </div>
        </Fragment>
    )
}

export default UploadByComputer

