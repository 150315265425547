import React from 'react'
import {useEditorBackground} from '../../../../../hooks/useEditorBackground'
import {useSelectedVariantContext} from '../../../context/SelectedVariantContext'
import {actionChangeApplyAllPlacements, actionUpdateBgColor} from '../../../context/SelectedVariantContext/action'
import {CMYK_COLORS} from '../../../context/SelectedVariantContext/constants'
import {SketchPicker} from 'react-color'
import {turnCmykToHex, turnHexToCmyk} from '../../../context/SelectedVariantContext/helper'

const ModalBackgroundColor = () => {
    const {
        state: {applyBgAllSide},
        dispatch,
    } = useSelectedVariantContext()
    const {fill: bgColor = 'transparent'} = useEditorBackground()

    const handleChangeApplyAllPlacements = (e) => {
        const {checked} = e.target
        dispatch(actionChangeApplyAllPlacements(checked))
    }

    const handleChange = (color) => {
        let selectedColor = '#ffffff'
        if (color.hex !== 'transparent') {
            // if (color.hex !== '#000000') {
            // convert hex từ color picker sang cmyk để giảm mã màu
            const hexToCmyk = turnHexToCmyk(color.hex)

            // sau đó đổi lại từ cmyk sang hex để set vào layer (vì layer không nhận mã màu cmyk)
            selectedColor = turnCmykToHex(hexToCmyk)
            // }
        }

        dispatch(actionUpdateBgColor(selectedColor))
    }

    return (
        <div className="MockupBackgroundColor">
            <div className="me-2">
                <h3>Background color</h3>
                <div className="CboApplyAllPlacements custom-control custom-checkbox">
                    <input
                        className="background_color custom-control-input form-check-input mt-0"
                        id="InputApplyAllPlacements"
                        type="checkbox"
                        checked={applyBgAllSide}
                        onChange={handleChangeApplyAllPlacements}
                    />
                    <label
                        id={'select_background_color'}
                        className={'background_color'}
                        htmlFor="InputApplyAllPlacements"
                    >
                        Apply to all placements
                    </label>
                </div>
            </div>
            <SketchPicker
                color={bgColor}
                colors={CMYK_COLORS}
                onChange={handleChange}
                disableAlpha={true}
                className="CustomCompactPicker"
            />
        </div>
    )
}

// ModalUploadFont.propsType = {
//     isOpen: PropsType.bool.required
// }

export default ModalBackgroundColor

